import { template as template_837d27084a2e45349a1fb95822704daf } from "@ember/template-compiler";
const FKLabel = template_837d27084a2e45349a1fb95822704daf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
