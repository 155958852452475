import { template as template_dbcf2380744a4182ac1cdee1bd7c4f5b } from "@ember/template-compiler";
const SidebarSectionMessage = template_dbcf2380744a4182ac1cdee1bd7c4f5b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
